import React from 'react'
import {cn} from '../lib/helpers'
import PostPreview from './post-preview'
import typography from './typography.module.css'
import styles from './post-section.module.css'
import Img from 'gatsby-image'

function PostSection ({title, posts, slug, image}) {
  return (
    <section className={slug.current}>
      <a id={slug.current} />
      {title &&
        <h2 className={cn(typography.responsiveTitle2, styles.title)}>
          {image && <Img fluid={image.asset.fluid} className={styles.icon} />}
          {title}
        </h2>}
      <div className={styles.postWrapper}>
        {posts && posts.map((node) => {
          return <PostPreview key={node.id} {...node} />
        })}
      </div>
    </section>
  )
}

PostSection.defaultProps = {
  title: '',
  posts: []
}

export default PostSection
