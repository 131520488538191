import React, {useState} from 'react'
import {cn} from '../lib/helpers'
import InlinePlayer from './inline-player'
import typography from './typography.module.css'
import styles from './audio-section.module.css'
import Img from 'gatsby-image'

function AudioSection ({audios, image}) {
  const [selectedAudioCat, setSelectedAudioCat] = useState('all')
  var audioCats = {all: {count: audios.length, title: 'All', hex: '#000'}}
  audios.map(
    (audio) => {
      audio.audioCategories.map((cat) => {
        if (!audioCats[cat.slug.current]) {
          audioCats[cat.slug.current] = {count: 1, title: cat.title, hex: cat.color.hex}
        } else {
          (audioCats[cat.slug.current]).count = (audioCats[cat.slug.current]).count + 1
        }
      })
    }
  )

  return (
    <section className='library'>
      <a id='library' />
      <h2 className={cn(typography.responsiveTitle2, styles.title)}>
        <Img fluid={image.asset.fluid} className={styles.icon} />
        Composer Reel
      </h2>
      <div className={styles.categoryFilter}>
        {Object.keys(audioCats).map((cat) => {
          const catSelected = cat === selectedAudioCat ? styles.selected : null
          const catColor = (audioCats[cat]).hex
          return <span
            key={cat}
            onClick={() => setSelectedAudioCat(cat)}
            style={{
              color: catSelected ? 'white' : catColor,
              backgroundColor: catSelected ? catColor : 'white'
            }}
            className={cn(styles.category, catSelected, cat)}
          >
            {(audioCats[cat]).title} ({(audioCats[cat]).count})
          </span>
        })}
      </div>
      <div className={cn(styles.postWrapper, 'scrollable')}>
        {audios && audios.map(
          (audio) => {
            return selectedAudioCat === 'all' || audio.audioCategories.filter(cat =>
              cat.slug.current === selectedAudioCat).length > 0
              ? <InlinePlayer key={audio._id} catColor={selectedAudioCat === 'all'
                ? null
                : (audioCats[selectedAudioCat]).hex} {...audio} />
              : null
          }
        )}
      </div>
      <div className={styles.divider} />
    </section>
  )
}

AudioSection.defaultProps = {
  title: '',
  audios: []
}

export default AudioSection
