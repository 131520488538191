import React from 'react'
import {cn} from '../lib/helpers'
import PortableText from './portableText'
import styles from './post-preview.module.css'
import typography from './typography.module.css'
import EmbeddedVideo from './embedded-video'

function PostPreview (props) {
  const {title, _rawBody, embeddedVideo} = props
  return (
    <article>
      {embeddedVideo &&
        <EmbeddedVideo videoID={embeddedVideo.videoID} provider={embeddedVideo.provider} />}
      <h3 className={cn(typography.responsiveTitle3, styles.title)}>{title}</h3>
      {_rawBody && (
        <div className={cn(styles.body, typography.small)}>
          <PortableText blocks={_rawBody} />
        </div>
      )}
    </article>
  )
}

export default PostPreview
