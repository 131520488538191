import React, {useState, useRef} from 'react'
import YouTube from 'react-youtube'
import Vimeo from 'react-vimeo'
import styled from 'styled-components'

const VideoStyles = styled.div`
  .vimeo {
    height: 390px;
    background: black;
  }

  iframe {
    height: 390px;
  }

  .vimeo-image {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .vimeo-play-button, .playVideo {
    width: 5em;
    height: 5em;
    padding: 1em;
    background: white;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    
    svg {
      margin-left: 0.25em;
      width: 100%;
      height: 100%;
      transition: opacity 0.5s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .videoWrapper {
    position: relative;

    .videoCover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .playVideo {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -2.5em;
      margin-top: -2.5em;
    }
  }
`

function EmbeddedVideo (props) {
  const {videoID, provider} = props
  const youtubeOptions = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      modestbranding: 1,
      rel: 0
    }
  }

  // Show cover image on top of embed
  const youtubeImageURL = `https://img.youtube.com/vi/${videoID}/0.jpg`
  const [coverVisible, setCoverVisible] = useState(true)
  const [videoPlayer, setVideoPlayer] = useState(null)
  const handleCoverClicked = function () {
    // The video cover was clicked, hide cover and play video
    setCoverVisible(false)
    videoPlayer.playVideo()
  }

  return <VideoStyles>
    {provider.includes('youtube') &&
      <div className='videoWrapper'>
        {coverVisible &&
          <img
            className='videoCover'
            src={youtubeImageURL}
            alt='Video preview'
          />}
        {videoPlayer && coverVisible &&
          <button className='playVideo' onClick={() => handleCoverClicked()}>
            <svg version='1.1' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
              <path d='M79.674,53.719c2.59-2.046,2.59-5.392,0-7.437L22.566,1.053C19.977-0.993,18,0.035,18,3.335v93.331c0,3.3,1.977,4.326,4.566,2.281L79.674,53.719z' />
            </svg>
          </button>}
        <YouTube
          videoId={videoID}
          key={videoID}
          opts={youtubeOptions}
          onReady={(event) => setVideoPlayer(event.target)}
        />
      </div> }
    {provider.includes('vimeo') &&
      <Vimeo key={videoID} videoId={videoID} /> }
  </VideoStyles>
}

export default EmbeddedVideo
