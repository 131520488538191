import React from 'react'
import {graphql} from 'gatsby'
import PortableText from '../components/portableText'
import AudioSection from '../components/audio-section'
import PostSection from '../components/post-section'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import typography from '../components/typography.module.css'
import {Spotify} from '@styled-icons/boxicons-logos/Spotify'
import {MusicAlbum} from '@styled-icons/zondicons/MusicAlbum'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './index.module.css'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      _rawAbout
      _rawIntro
      mainImage {
        ...SanityImage
        alt
      }
      audiosImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      audios {
        _id
        slug {
          current
        }
        title
        audio {
          asset {
            url
          }
        }
        audioCategories {
          slug {
            current
          }
          title
          color {
            hex
          }
        }
      }
      postSections {
        title
        slug {
          current
        }
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }

        posts {
          categories {
            _id
            title
            slug {
              current
            }
          }
          id
          title
          mainImage {
            ...SanityImage
            alt
          }
          _rawBody
          embeddedVideo {
            videoID
            provider
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  if (!site) {
    throw new Error(
      'Missing "Site settings".'
    )
  }

  const postSections = site.postSections.map(
    (postSection, index) => {
      return <PostSection
        key={`postSection-${index}`}
        title={postSection.title}
        posts={postSection.posts}
        slug={postSection.slug}
        image={postSection.image}
      />
    }
  )

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        image={site.mainImage}
        keywords={[]}
      />
      <Container>
        <div className={styles.siteDescription}>
          <PortableText blocks={site._rawIntro} />
        </div>

        <p className={styles.socials}>
          <MusicAlbum className={styles.otherMusic} /> <a target='_blank' href='https://utter.band'>
            Utter</a><br />
          <Spotify className={styles.spotify} /> <a target='_blank' href='https://open.spotify.com/artist/5FaSE7c8jH4ks529SdqThF?si=0V2jxl3CT3-JQf9jq0zlog'>
            Hieronymous on Spotify</a><br />
          <Spotify className={styles.spotify} /> <a target='_blank' href='https://open.spotify.com/artist/7GCClMjaGLlxOOP2Goa3CD?si=MyTtUMtwTwe4842xYkHMTQ'>
            Swoon on Spotify</a><br />
          <Spotify className={styles.spotify} /> <a target='_blank' href='https://open.spotify.com/artist/2Ag3w3cSdRHgzPNi60WABE?si=27QPLr-5RK-F8PgUjFMwvw'>
            Fanfarlo on Spotify</a><br />
        </p>

        {/* Show first 2 project sections */}
        {postSections.slice(0, 2)}

        {/* Show library picks section */}
        <AudioSection audios={site.audios} image={site.audiosImage} />

        {/* Show any remaining project sections */}
        {postSections.length > 2 && postSections.slice(2)}

        {/* About section */}
        <div className={styles.landingPageAbout}>
          <a id='about' />
          <h2 className={typography.responsiveTitle2}>
            About Simon
          </h2>
          <div className={styles.contentWrapper}>
            {site.mainImage && site.mainImage.asset && (
              <div className={styles.imageWrapper}>
                <img
                  src={imageUrlFor(buildImageObj(site.mainImage))
                    .width(1200)
                    .height(Math.floor((9 / 16) * 1200))
                    .fit('crop')
                    .auto('format')
                    .url()}
                  alt={site.mainImage.alt}
                />
              </div>
            )}

            <PortableText blocks={site._rawAbout} />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPage
