import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {PlayCircle} from '@styled-icons/boxicons-regular/PlayCircle'
import {PauseCircle} from '@styled-icons/boxicons-regular/PauseCircle'
import {FastRewind} from '@styled-icons/material-rounded/FastRewind'
import {FastForward} from '@styled-icons/material-rounded/FastForward'

const PlayerStyles = styled.div`
  .title {
    margin-top: 0.5rem;
    text-align: center;
  }

  .buttonWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .button {
    height: 3em;
    width: 3em;
    cursor: pointer;
    transition: all 0.5s;
    color: white;

    &:hover {
      opacity: 0.5;
    }
  }

  .progress {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: red;
  }

  .duration {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    color: white;

    @media (max-width: 500px) {
      display: none;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

function formatDuration (durationSec) {
  const seconds = (Math.floor(durationSec % 60)).toString().padStart(2, '0')
  const minutes = (Math.floor(durationSec / 60)).toString().padStart(2, '0')
  return `${minutes}:${seconds}`
}

function InlinePlayer ({audio, title, audioCategories, catColor}) {
  if (!audio) return null

  const [playing, setPlaying] = useState(false)
  const [loading, setLoading] = useState(true)
  const [duration, setDuration] = useState(0)
  const [position, setPosition] = useState(0)
  const [player, setPlayer] = useState(null)

  // const player = new Audio(audio.asset.url)
  useEffect(() => {
    if (!audio) return
    const newPlayer = new Audio(audio.asset.url)
    setPlayer(newPlayer)
    newPlayer.addEventListener('ended', () => setPlaying(false))
    newPlayer.addEventListener('canplay', () => setLoading(false))
    newPlayer.addEventListener('loadedmetadata', () => setDuration(newPlayer.duration))
    newPlayer.addEventListener('timeupdate', () => setPosition(newPlayer.currentTime))
  }, [])

  useEffect(() => {
    if (player) {
      playing ? player.play() : player.pause()
    }
    // Return effect cleanup
    return () => { if (player) { player.pause() } }
  }, [playing]) // Trigger when playing changes

  function togglePlay () {
    // Using the callback version of `setState` so you always
    // toggle based on the latest state
    setPlaying(s => !s)
  }

  function jumpBack () {
    if (player && playing) {
      const newPosition = Math.max(player.currentTime - 10, 0)
      player.currentTime = newPosition
    }
  }

  function jumpForward () {
    if (player && playing) {
      const newPosition = Math.min(player.currentTime + 10, duration)
      player.currentTime = newPosition
    }
  }

  const percentDuration = position > 0 ? Math.ceil(position / duration * 100) : 0
  const color = catColor || (audioCategories
    ? (audioCategories[0]).color.hex
    : 'white')

  return <PlayerStyles>
    <div className='buttonWrapper' style={{backgroundColor: color}}>
      <span className='duration'>{formatDuration(duration)}</span>
      <div className='button' onClick={() => jumpBack()} role='button'>
        <div
          className={`button ${(!playing || position <= 0) && 'disabled'}`}
          onClick={() => jumpBack()}
          role='button'
        >
          <FastRewind />
        </div>
      </div>
      <div className='button' onClick={() => togglePlay()} role='button'>
        {playing ? <PauseCircle /> : <PlayCircle /> }
      </div>
      <div
        className={`button ${(!playing || position >= duration) && 'disabled'}`}
        onClick={() => jumpForward()}
        role='button'
      >
        <FastForward />
      </div>
      <span className='progress' style={{width: `${percentDuration}%`}} />
    </div>
    <p className='title'>
      {!loading && title}
      {loading && ' Loading...'}
    </p>
  </PlayerStyles>
}

export default InlinePlayer
